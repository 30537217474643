// src/utils/fetchUserInfo.js
import { calculateRecurrence } from './recurrenceUtils';

export async function fetchUserInfo({
  filters,
  escalas,
  user,
  getAllEvents,
  getUserInfo,
}) {
  const { selectedCompany, selectedEscala, startDate, endDate } = filters;

  if (!selectedCompany || !selectedEscala || !startDate || !endDate) return [];

  const currentEscala = escalas.find(
    (escala) => escala.escala_id === selectedEscala
  );
  const userId = currentEscala.gestor ?? user.uid;

  const events = await getAllEvents({ userId, escalaId: selectedEscala });

  const result = calculateRecurrence({
    evts: events,
    startOfCurrentMonth: startDate,
    endOfCurrentMonth: endDate,
  });

  const userInfo = {};

  await Promise.all(
    Object.keys(result).map(async (date) => {
      const userIds = Object.keys(result[date]);

      await Promise.all(
        userIds.map(async (userId) => {
          const team = result[date][userId][0]?.doctors[userId].team;
          if (!userInfo[userId]) {
            const userResult = await getUserInfo(
              userId,
              team,
              currentEscala.gestor ?? user.uid
            );
            userInfo[userId] = userResult;
          }
        })
      );
    })
  );

  return { result, userInfo, currentEscala };
}
